export const theme = {
	palette: {
		background: {
			default: "#ffffff",
			light: "#F2F0EC"
		},
		primary: {
			light: "#C72E1D",
			main: "#C72E1D",
			dark: "#8D2115"
		},
		secondary: {
			light: "#C72E1D",
			main: "#C72E1D",
			dark: "#C72E1D"
		},
		text: {
			primary: "#41352C",
			secondary: "#A29786"
		}
	},
	typography: {
		fontFamily: `"Manrope"`,
		h1: {
			fontFamily: "Bree Serif",
			fontWeight: 400,
			fontSize: 37,
			lineHeight: "45px"
		},
		h2: {
			fontFamily: "Bree Serif",
			fontWeight: 400,
			fontSize: 37,
			lineHeight: "45px"
		}
	}
};
