import React, { Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { StylesProvider } from "@material-ui/core/styles";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import { theme as customTheme } from "./themes/project";

const BlogDetailInfo = loadable(() => import("./lib/components/BlogDetailInfo"));
const BloggerDescription = loadable(() => import("./lib/components/BloggerDescription"));
const BlogTags = loadable(() => import("./lib/components/BlogTags"));
const BlogShare = loadable(() => import("./lib/components/BlogShare"));
const ListBlogger = loadable(() => import("./lib/components/ListBlogger"));
const BusStops = loadable(() => import("./lib/components/BusStops"));

const theme = createTheme(customTheme);

const $listBlogger = document.querySelectorAll(".react-list-blogger");
const $blogDetailInfo = document.querySelectorAll(".react-blog-detail-info");
const $blogDescription = document.querySelectorAll(".react-blogger-description");
const $blogShare = document.querySelectorAll(".react-blog-share");
const $blogTags = document.querySelectorAll(".react-blog-tags");
const $busStops = document.querySelectorAll(".react-bus-stops");

if ($listBlogger) {
	$listBlogger.forEach(element => {
		const lang = element?.getAttribute("data-lang");
		const selected = element?.getAttribute("data-selected");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<Ui>
							<ListBlogger lang={lang} selected={selected?.replaceAll("|", ",")} />
						</Ui>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($blogDetailInfo) {
	$blogDetailInfo.forEach(element => {
		const lang = element?.getAttribute("data-lang");
		const id = element?.getAttribute("data-authorID");
		const blogDate = element?.getAttribute("data-blogDate");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={<BlogDetailInfo authorID={id} blogDate={blogDate} lang={lang} />}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($blogDescription) {
	$blogDescription.forEach(element => {
		const lang = element?.getAttribute("data-lang");
		const id = element?.getAttribute("data-authorID");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<Ui>
							<BloggerDescription authorID={id} lang={lang} />
						</Ui>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($blogShare) {
	$blogShare.forEach(element => {
		const label = element?.getAttribute("data-label");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<Ui>
							<BlogShare label={label} />
						</Ui>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($blogTags) {
	$blogTags.forEach(element => {
		const params = element?.getAttribute("data-params");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<WebPageData>
									<Routes>
										<Route
											path="/*"
											element={
												<WebProfile>
													<BlogTags params={params} />
												</WebProfile>
											}
										/>
									</Routes>
								</WebPageData>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($busStops) {
	$busStops.forEach(element => {
		const lang = element?.getAttribute("data-lang");
		const downloadFile = element?.getAttribute("data-download");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="*"
										element={<BusStops downloadFile={downloadFile} lang={lang} />}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}
